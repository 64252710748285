import ContentfulImage from '@/components/contentful-image';
import ContentfulVideo from '@/components/contentful-video';
import { CardIC, CardProps, SectionCardIC } from '@/lib/interface-types';
import React, { ReactElement } from 'react';
import CardDeepBlue from './templates/card-deep-blue';
import CardDescriptionImage from './templates/card-description-image';
import CardGreenBorder from './templates/card-green-border';
import CardImage from './templates/card-image';
import CardSimpleIconLink from './templates/card-simple-icon-link';
import CardSimpleLeft from './templates/card-simple-left';
import CardSimpleRight from './templates/card-simple-right';
import CardTemplate1 from './templates/card-template1';
import CardTemplate2 from './templates/card-template2';
import CardTemplate3 from './templates/card-template3';
import CardTemplate4 from './templates/card-template4';
import CardTemplate5 from './templates/card-template5';
import CardTemplate6 from './templates/card-template6';
import CardTemplate7 from './templates/card-template7';
import CardText from './templates/card-text';
import CardWhite from './templates/card-white';

const Card = (props: CardIC) => {
  interface CardTemplates {
    [template: string]: React.ComponentType<CardProps>;
  }
  const { sectionLayout, classIndex, slug, sectionPadding, card } = props;
  const { videoUrl, cardImage, onlyImagevideo, cardTemplate }: SectionCardIC =
    card;
  let spanClass = '';

  if (sectionLayout === '1/3 1/3 1/3') {
    spanClass = 'col-span-1';
  } else if (sectionLayout === '1/2 1/2 - simple-card-link') {
    spanClass = '';
  } else if (classIndex === 0) {
    spanClass = '';
  } else if ((classIndex - 1) % 4 === 0 || (classIndex - 2) % 4 === 0) {
    spanClass = 'col-span-1 lg:col-span-2';
  } else {
    spanClass = ' ';
  }

  if (sectionLayout === '1/2 1/2') {
    spanClass = 'col-span-1';
  }

  const isAccordionLayout =
    sectionLayout === 'left' ||
    sectionLayout === 'right' ||
    sectionLayout === 'full-width' ||
    sectionLayout === 'Accordion left - Article' ||
    sectionLayout === 'Accordion right - Article' ||
    sectionLayout === 'Accordion left - Category Page' ||
    sectionLayout === 'Accordion right - Category Page' ||
    sectionLayout === 'Accordion full-width';

  let height = '';
  if (sectionLayout === '1/1') {
    height = 'h-[330px] xl:h-[500px] 2xl:h-[600px]';
  } else if (isAccordionLayout) {
    height = 'h-[330px] 2xl:h-[450px]';
  } else if (sectionLayout === '1/2 1/2') {
    height = 'h-[330px] min-[1700px]:h-[400px]';
  } else {
    height = 'h-[330px] min-[1700px]:h-[400px]';
  }

  const templateComponents: CardTemplates = {
    'Template 1': CardTemplate1,
    'Template 2': CardTemplate2,
    'Template 3': CardTemplate3,
    'Template 4': CardTemplate4,
    'Template 5': CardTemplate5,
    'Template 6': CardTemplate6,
    'Template 7': CardTemplate7,
    'card-white': CardWhite,
    'card-deep-blue': CardDeepBlue,
    'card-green-border': CardGreenBorder,
    'card-image': CardImage,
    'card-description-image': CardDescriptionImage,
    'card-text': CardText,
    'card-simple-right': CardSimpleRight,
    'card-simple-left': CardSimpleLeft,
    'card-simple-icon-link': CardSimpleIconLink
  };

  let content: ReactElement | null = null;

  const _card = { ...card };

  if (_card.linkUrl === null) {
    _card.linkUrl = '';
  }
  if (!onlyImagevideo) {
    const TemplateComponent = cardTemplate
      ? templateComponents[cardTemplate]
      : CardTemplate6;
    if (TemplateComponent) {
      content = (
        <div className={`h-full ${sectionPadding ? 'px-0 lg:px-4' : ''}`}>
          <TemplateComponent
            card={_card}
            slug={slug}
            sectionPadding={sectionPadding}
          />
        </div>
      );
    }
  } else {
    content = (
      <>
        {videoUrl && cardImage && (
          <>
            <ContentfulVideo
              height={height}
              youtubeVideo={videoUrl}
              cardImage={cardImage}
            />
          </>
        )}

        {cardImage?.url && !videoUrl && (
          <div className={`lg:w-full relative ${height}`}>
            <ContentfulImage
              src={cardImage?.url}
              alt={cardImage?.title}
              className="object-cover"
              fill
              sizes="99.99vw"
            />
          </div>
        )}
      </>
    );
  }

  return <div className={`Card-cft relative ${spanClass}`}>{content}</div>;
};

export default Card;
